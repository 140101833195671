











































import Vue from "vue";
import store from "@/store/index";
import axios from "axios";
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";
import Title from "@/components/Title.vue";
import { host } from "@/host";

export default Vue.extend({
  store,
  components: {
    SimpleKeyboard,
    Title
  },
  data: () => ({
    input: "",
    type: "text",
    placeholder: "",
    hint: "",
    loading: false,
    stage: "",
    email: "",
    password: "",
    ckey: "",
    deviceName: "",
    showBack: false
  }),
  methods: {
    onChange(input: string) {
      this.input = input;
    },
    onKeyPress(button: string) {
      if (button == "{enter}" && !this.loading) {
        switch (this.stage) {
          case "email":
            this.email = this.input;
            this.showBack = true;
            this.setStagePassword();
            break;
          case "password":
            this.setLoading(true);
            this.password = this.input;
            axios
              .post(host + "/api/v1/user/login", {
                email: this.email,
                password: this.password,
                admin: true
              })
              .then(res => {
                this.ckey = res.data.key;
                this.setStageDeviceName();
              })
              .catch(err => {
                if (err.response && err.message) {
                  this.hint = err.response.data.status;
                } else {
                  this.hint = err.message;
                }
              })
              .then(() => {
                this.setLoading(false);
              });
            break;
          case "deviceName":
            this.setLoading(true);
            this.deviceName = this.input;
            axios
              .post(host + "/api/v1/client/authenticate", {
                ckey: this.ckey,
                name: this.deviceName
              })
              .then(res => {
                store.state.dkey = res.data.key;
                store.commit("save");

                this.$router.push("/client").catch(() => {
                  return true;
                });
              })
              .catch(err => {
                if (err.response && err.message) {
                  this.hint = err.response.data.status;
                } else {
                  this.hint = err.message;
                }
              })
              .then(() => {
                this.setLoading(false);
              });
            break;
        }
      }
    },
    setStageEmail() {
      this.placeholder = "lgroe19@student.sdu.dk";
      this.hint = "Enter your SDU email address";
      this.stage = "email";
      this.input = this.email;
      this.type = "text";
    },
    setStagePassword() {
      this.placeholder = "$$Good69Password";
      this.hint = "Please enter your password";
      this.stage = "password";
      this.input = this.password;
      this.type = "password";
    },
    setStageDeviceName() {
      this.placeholder = "Device name";
      this.hint = "Please enter a name for the device";
      this.stage = "deviceName";
      this.input = this.deviceName;
      this.type = "text";
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    onBack() {
      switch (this.stage) {
        case "password":
          this.showBack = false;
          this.setStageEmail();
          break;
        case "deviceName":
          this.setStagePassword();
          break;
      }
    },
    onHome() {
      this.$router.push("/client").catch(() => {
        return true;
      });
    }
  },
  mounted() {
    this.setStageEmail();
  }
});
