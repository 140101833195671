
















































































































































import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import store from "@/store/index";
import Title from "@/components/Title.vue";
import EventBus from "@/classes/EventBus";
import Plus from "vue-material-design-icons/LinkPlus.vue";
import Cancel from "vue-material-design-icons/Cancel.vue";
import Delete from "vue-material-design-icons/LinkOff.vue";
import OneMarkModal from "@/components/OneMark.vue";
import { OneMark } from "@/components/OneMark.vue";
import MultiModeModal from "@/components/MultiMode.vue";
import { MultiMode } from "@/components/MultiMode.vue";
import HelpModal from "@/components/Help.vue";

interface Modal extends Vue {
  show(): void;
}

export default Vue.extend({
  store,
  components: {
    Title,
    Cancel,
    Plus,
    Delete,
    OneMarkModal,
    MultiModeModal,
    HelpModal
  },
  data: () => ({
    RFID: false,
    MagID: false,
    title: "",
    subtitle: "",
    show: false,
    addingRFID: false,
    deletingRFID: false,
    addingMagID: false,
    deletingMagID: false,
    cardStatus: "",
    cardWait: false,
    privilegeLevel: 0,
    lastActivity: 0,
    intervalID: 0
  }),
  methods: {
    multiMode(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        (this.$refs.multimode as MultiMode).show(
          true,
          store.state.ckey,
          store.state.dkey,
          (balance: number) => {
            this.updateSubTitle(balance);
          }
        );

        this.markActivity();
      }
    },
    oneMark(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        (this.$refs.onemark as OneMark).pay(
          store.state.ckey,
          store.state.dkey,
          (balance: number) => {
            this.updateSubTitle(balance);
          }
        );

        this.markActivity();
      }
    },
    showHelp(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        (this.$refs.help as Modal).show();

        this.markActivity(40000);
      }
    },
    onMangeCards(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        this.$bvModal.show("manageCardsModal");

        this.markActivity(40000);
      }
    },
    deleteMagID() {
      this.markActivity(30000);

      this.deletingMagID = true;

      this.cardStatus = "Please wait...";

      axios
        .delete(host + "/api/v1/user/card", {
          params: { key: store.state.ckey, type: "MagID" }
        })
        .then(() => {
          this.cardStatus = "Card deleted!";
          this.MagID = false;
        })
        .catch(err => {
          if (err.response && err.message) {
            this.cardStatus = err.response.data.status;
          } else {
            this.cardStatus = err.message;
          }
        })
        .then(() => {
          this.deletingMagID = false;
        });
    },
    addMagID() {
      this.markActivity(30000);

      this.addingMagID = true;

      if (document.activeElement) {
        (document.activeElement as HTMLButtonElement).blur();
      }
      //this.cardStatus = "Please swipe your card!";

      EventBus.$on("CardRead", (ID: string, type: string) => {
        if (type == "MagID") {
          EventBus.$off("CardRead");

          this.cardStatus = "Please wait...";
          this.cardWait = true;

          axios
            .post(host + "/api/v1/user/card", {
              key: store.state.ckey,
              dkey: store.state.dkey,
              type: type,
              ID: ID
            })
            .then(() => {
              const audio = new Audio(require("../../../assets/success.mp3"));
              audio.play();

              this.cardStatus = "Card added!";
              this.MagID = true;
            })
            .catch(err => {
              const audio = new Audio(require("../../../assets/thenoise.mp3"));
              audio.play();

              if (err.response && err.message) {
                this.cardStatus = err.response.data.status;
              } else {
                this.cardStatus = err.message;
              }
            })
            .then(() => {
              this.addingMagID = false;
              this.cardWait = false;
            });
        }
      });
    },
    cancelAddMagID() {
      this.markActivity(30000);

      this.addingMagID = false;
      this.cardStatus = "";
      EventBus.$off("CardRead");
    },
    deleteRFID() {
      this.markActivity(30000);

      this.deletingRFID = true;

      this.cardStatus = "Please wait...";

      axios
        .delete(host + "/api/v1/user/card", {
          params: { key: store.state.ckey, type: "RFID" }
        })
        .then(() => {
          this.cardStatus = "Card deleted!";
          this.RFID = false;
        })
        .catch(err => {
          if (err.response && err.message) {
            this.cardStatus = err.response.data.status;
          } else {
            this.cardStatus = err.message;
          }
        })
        .then(() => {
          this.deletingRFID = false;
        });
    },
    addRFID() {
      this.markActivity(30000);

      this.addingRFID = true;

      if (document.activeElement) {
        (document.activeElement as HTMLButtonElement).blur();
      }

      //this.cardStatus = "Please scan your card!";

      EventBus.$on("CardRead", (ID: string, type: string) => {
        if (type == "RFID") {
          EventBus.$off("CardRead");

          this.cardStatus = "Please wait...";
          this.cardWait = true;

          axios
            .post(host + "/api/v1/user/card", {
              key: store.state.ckey,
              dkey: store.state.dkey,
              type: type,
              ID: ID
            })
            .then(() => {
              const audio = new Audio(require("../../../assets/success.mp3"));
              audio.play();

              this.cardStatus = "Card added!";
              this.RFID = true;
            })
            .catch(err => {
              const audio = new Audio(require("../../../assets/thenoise.mp3"));
              audio.play();

              if (err.response && err.message) {
                this.cardStatus = err.response.data.status;
              } else {
                this.cardStatus = err.message;
              }
            })
            .then(() => {
              this.addingRFID = false;
              this.cardWait = false;
            });
        }
      });
    },
    cancelAddRFID() {
      this.markActivity(30000);

      this.addingRFID = false;
      this.cardStatus = "";
      EventBus.$off("CardRead");
    },
    onHome() {
      clearInterval(this.intervalID);

      axios.delete(host + "/api/v1/user/login", {
        params: { key: store.state.ckey }
      });
      store.state.ckey = "";

      this.$router.push("/client").catch(() => {
        return true;
      });
    },
    updateSubTitle(balance: number) {
      if (balance >= 50) {
        this.subtitle = `Your current balance is ${balance} DKK`;
      } else if (balance < 50 && balance > 0) {
        this.subtitle = `Your current balance is ${balance} DKK, please consider adding more funds at aeter.dk`;
      } else {
        this.subtitle = `Your current balance is ${balance} DKK, please add more funds at aeter.dk`;
      }
    },
    markActivity(x = 0) {
      this.lastActivity = new Date().getTime() + x;
    }
  },
  mounted() {
    if (store.state.ckey != "") {
      axios
        .get(host + "/api/v1/user", { params: { key: store.state.ckey } })
        .then(res => {
          this.show = true;

          this.title = `Hi ${
            res.data.doc.name.split(" ")[0]
          }, welcome to AeterPay!`;

          this.updateSubTitle(res.data.doc.balance);

          this.RFID = res.data.doc.RFID;
          this.MagID = res.data.doc.MagID;
          this.privilegeLevel = res.data.doc.privilegeLevel;

          if (store.state.newAccount) {
            store.state.newAccount = false;
            setTimeout(() => {
              this.$bvModal.show("manageCardsModal");
            }, 800);
          }
        })
        .catch(() => {
          this.$router.push("/client").catch(() => {
            return true;
          });
        });
    } else {
      this.$router.push("/client").catch(() => {
        return true;
      });
    }

    this.markActivity();

    this.intervalID = setInterval(() => {
      if (this.lastActivity + 15000 < new Date().getTime()) {
        if (this.addingRFID) {
          this.cardStatus = "";
          this.addingRFID = false;
          EventBus.$off("CardRead");
        }

        if (this.addingMagID) {
          this.cardStatus = "";
          this.addingMagID = false;
          EventBus.$off("CardRead");
        }

        this.onHome();
      }
    }, 5000);
  }
});
