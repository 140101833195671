































































import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import store from "@/store/index";

export default Vue.extend({
  data: () => ({
    amount: "",
    status: "",
    loading: false,
    acceptTOS: false
  }),
  methods: {
    gotoPayment() {
      if (this.acceptTOS) {
        this.loading = true;
        this.status = "Please wait...";

        axios
          .post(host + "/api/v1/transaction/payment/generate", {
            ckey: store.state.ckey,
            amount: this.amount
          })
          .then(res => {
            window.location = res.data.url;
          })
          .catch(err => {
            this.loading = false;
            this.status = err.response.data.status;
          });
      } else {
        this.status =
          "Please read and accept the Privacy Policy and the Terms of Service";
      }
    },
    show() {
      this.amount = "120";
      this.status = "";
      this.acceptTOS = false;
      this.$bvModal.show("paymentModal");
    },
    hide() {
      this.$bvModal.hide("paymentModal");
    },
    checkAmount(val: string) {
      const reg = /^\d+$/;

      if (reg.test(val) || val == "") {
        return val;
      } else {
        return this.amount;
      }
    }
  }
});
