







































































































































































































import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import store from "@/store/index";
import PaymentPortal from "@/components/PaymentPortal.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import DeleteAccount from "@/components/DeleteAccount.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import { Confirm } from "@/components/ConfirmModal.vue";
import Navbar from "@/components/Navbar.vue";

export default Vue.extend({
  components: {
    PaymentPortal,
    ChangePassword,
    DeleteAccount,
    ConfirmModal,
    Navbar
  },
  data: () => ({
    balance: 0,
    name: "loading",
    email: "loading",
    oldEmail: "",
    RFID: false,
    MagID: false,
    loading: true,
    payIP: false,
    payURL: "",
    editName: false,
    nameButtonText: "Update name",
    savingName: false,
    editEmail: false,
    emailButtonText: "Update email",
    savingEmail: false,
    savingRFID: false,
    savingMagID: false,
    pendingBalance: 0
  }),
  methods: {
    cancelOrder() {
      axios
        .delete(host + "/api/v1/transaction", {
          params: {
            url: this.payURL
          }
        })
        .then(() => {
          this.payIP = false;
          this.payURL = "";
        })
        .catch(err => {
          let theError;

          if (err.response) {
            theError = err.response.data.status;
          } else {
            theError = err.message;
          }

          this.$bvToast.toast(theError, {
            toaster: "b-toaster-bottom-right",
            title: "An error occured",
            variant: "warning",
            solid: true
          });
        });
    },
    unlinkCard(type: string) {
      if (type == "MagID") {
        this.savingMagID = true;
      } else {
        this.savingRFID = true;
      }

      axios
        .delete(host + "/api/v1/user/card", {
          params: { key: store.state.ckey, type: type }
        })
        .then(() => {
          (this.$refs.ConfirmModal as Confirm).onSuccess();

          if (type == "MagID") {
            this.savingMagID = false;
            this.MagID = false;
          } else {
            this.savingRFID = false;
            this.RFID = false;
          }
        })
        .catch(err => {
          let theError;

          if (err.response) {
            theError = err.response.data.status;
          } else {
            theError = err.message;
          }

          (this.$refs.ConfirmModal as Confirm).onFail(theError);
        })
        .then(() => {
          if (type == "MagID") {
            this.savingMagID = false;
          } else {
            this.savingRFID = false;
          }
        });
    },
    updateEmail() {
      if (this.editEmail) {
        if (this.oldEmail == this.email) {
          this.editEmail = false;
          this.emailButtonText = "Update email";
        } else {
          this.savingEmail = true;

          axios
            .post(host + "/api/v1/user/update/email", {
              key: store.state.ckey,
              email: this.email
            })
            .then(() => {
              this.email = this.email.toLowerCase();
              this.editEmail = false;
              this.emailButtonText = "Update email";
            })
            .catch(err => {
              let theError;

              if (err.response) {
                theError = err.response.data.status;
              } else {
                theError = err.message;
              }

              this.$bvToast.toast(theError, {
                toaster: "b-toaster-bottom-right",
                title: "An error occured",
                variant: "warning",
                solid: true
              });
            })
            .then(() => {
              this.savingEmail = false;
            });
        }
      } else {
        this.oldEmail = this.email;
        this.editEmail = true;
        this.emailButtonText = "Save email";
      }
    },
    updateName() {
      if (this.editName) {
        this.savingName = true;

        axios
          .post(host + "/api/v1/user/update/name", {
            key: store.state.ckey,
            name: this.name
          })
          .then(() => {
            this.editName = false;
            this.nameButtonText = "Update name";
          })
          .catch(err => {
            let theError;

            if (err.response) {
              theError = err.response.data.status;
            } else {
              theError = err.message;
            }

            this.$bvToast.toast(theError, {
              toaster: "b-toaster-bottom-right",
              title: "An error occured",
              variant: "warning",
              solid: true
            });
          })
          .then(() => {
            this.savingName = false;
          });
      } else {
        this.editName = true;
        this.nameButtonText = "Save name";
      }
    }
  },
  mounted() {
    axios
      .get(host + "/api/v1/user", { params: { key: store.state.ckey } })
      .then(res => {
        this.email = res.data.doc.email;
        this.name = res.data.doc.name;
        this.RFID = res.data.doc.RFID;
        this.MagID = res.data.doc.MagID;
        this.balance = res.data.doc.balance;

        let cpkey;

        if (this.$route.query.clientpaymentkey) {
          cpkey = this.$route.query.clientpaymentkey;
          this.$router.replace("/");
        }

        axios
          .get(host + "/api/v1/incompleteTransactions", {
            params: { key: store.state.ckey, cpkey: cpkey }
          })
          .then(res => {
            this.payIP = res.data.doc.payIP;

            if (this.payIP) {
              this.payURL = res.data.doc.payURL;
            }

            this.pendingBalance = res.data.doc.pendingBalance;

            this.loading = false;
          })
          .catch(() => {
            this.$router.push("/user/login").catch(() => {
              return true;
            });
          });
      })
      .catch(() => {
        this.$router.push("/user/login").catch(() => {
          return true;
        });
      });
  }
});
