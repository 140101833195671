


















































import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import Success from "vue-material-design-icons/CheckCircleOutline.vue";

export default Vue.extend({
  components: {
    Success
  },
  data: () => ({
    email: "",
    status: "",
    loading: false,
    success: false
  }),
  methods: {
    makeRequest() {
      this.loading = true;

      axios
        .post(host + "/api/v1/user/reset/password", { email: this.email })
        .then(() => {
          this.success = true;
          this.loading = false;

          setTimeout(() => {
            this.$bvModal.hide("passreqModal");
          }, 3000);
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.message) {
            this.status = err.response.data.status;
          } else {
            this.status = err.message;
          }
        });
    },
    show() {
      this.email = "";
      this.status = "";
      this.success = false;
      this.$bvModal.show("passreqModal");
    },
    hide() {
      this.$bvModal.hide("passreqModal");
    }
  }
});
