import store from "../store/index";
import axios from "axios";
import { host } from "../host";
import EventBus from "@/classes/EventBus";

class Checks {
  deviceIsAuthenticated(callback: Function) {
    if (store.state.dkey == "") return callback(null, false);

    axios
      .get(host + "/api/v1/client/authenticate", {
        params: { key: store.state.dkey }
      })
      .then(res => {
        if (!res.data.isAuthenticated) {
          store.state.dkey = "";
          store.state.remoteTarget = false;
          store.commit("save");
        }

        if (store.state.remoteTarget) {
          EventBus.$emit("setRemoteTarget", false);
        }

        callback(null, res.data.isAuthenticated);
      })
      .catch(err => {
        callback(err, false);
      });
  }
}

export default new Checks();
