

























import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import store from "@/store/index";
import Navbar from "@/components/Navbar.vue";

interface Item {
  finalBalance: number;
  balanceDifference: number;
  doneWith: string;
  complete: boolean;
  timeCreated: number;
  canceled: boolean;
}

export default Vue.extend({
  components: {
    Navbar
  },
  data: () => ({
    loading: true,
    transations: [],
    fields: [
      {
        key: "timeCreated",
        label: "Date",
        formatter: (value: number) => {
          return new Date(value).toLocaleString("en-GB");
        }
      },
      {
        key: "doneWith",
        label: "Description"
      },
      {
        key: "complete",
        label: "Status",
        formatter: (value: boolean, key: string, item: Item) => {
          if (value) {
            return "Complete";
          } else {
            if (item.canceled) {
              return "Cancelled";
            } else {
              return "Pending";
            }
          }
        }
      },
      {
        key: "balanceDifference",
        label: "Amount",
        formatter: (value: number) => {
          return value + ".00 DKK";
        }
      },
      {
        key: "finalBalance",
        label: "Balance",
        formatter: (value: number) => {
          return value + ".00 DKK";
        }
      }
    ]
  }),
  mounted() {
    axios
      .get(host + "/api/v1/transaction", { params: { key: store.state.ckey } })
      .then(res => {
        this.loading = false;
        this.transations = res.data.transactions;
      })
      .catch(() => {
        this.$router.push("/user/login").catch(() => {
          return true;
        });
      });
  }
});
