


















































import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import Success from "vue-material-design-icons/CheckCircleOutline.vue";

export default Vue.extend({
  components: {
    Success
  },
  data: () => ({
    key: "",
    password: "",
    passwordRepeat: "",
    status: "Please fill in the form to reset your password",
    error: false,
    success: false
  }),
  methods: {
    onSubmit(e: HTMLFormElement) {
      e.preventDefault();

      if (this.password == this.passwordRepeat) {
        this.error = false;
        this.status = "Please wait...";

        axios
          .post(host + "/api/v1/user/update/password/reset", {
            key: this.key,
            password: this.password
          })
          .then(() => {
            this.success = true;

            setTimeout(() => {
              this.$router.push("/user/login").catch(() => {
                return true;
              });
            }, 5000);
          })
          .catch(err => {
            this.error = true;
            if (err.response && err.message) {
              this.status = err.response.data.status;
            } else {
              this.status = err.message;
            }
          });
      } else {
        this.error = true;
        this.status = "The passwords must match!";
      }
    },
    cancel() {
      this.$router.push("/user/login").catch(() => {
        return true;
      });
    }
  },
  mounted() {
    if (this.$route.query.key) {
      this.key = this.$route.query.key as string;
    } else {
      this.$router.push("/user/login").catch(() => {
        return true;
      });
    }
  }
});
