


















import Vue from "vue";
import EventBus from "@/classes/EventBus";

export default Vue.extend({
  data: () => ({
    inputChars: "",
    lastTime: 0,
    transitionName: "",
  }),
  computed: {
    isClient() {
      return this.$route.path.substr(0, 7) == "/client";
    },
  },
  mounted() {
    document.title = "AeterPay";

    window.addEventListener("keypress", (e) => {
      if (this.lastTime == 0) {
        this.lastTime = new Date().getTime();
      }

      const curTime = new Date().getTime();

      if (curTime - this.lastTime > 25) {
        this.inputChars = "";
      }

      this.lastTime = curTime;

      if (e.key in ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]) {
        this.inputChars += e.key;
      }

      if (this.inputChars.length == 10 && e.key == "Enter") {
        EventBus.$emit("CardRead", this.inputChars, "RFID");
        this.inputChars = "";
      }

      if (this.inputChars.length == 35 && e.key == "Enter") {
        EventBus.$emit("CardRead", this.inputChars, "MagID");
        this.inputChars = "";
      }
    });

    EventBus.$on("setRemoteTarget", (manual: boolean) => {
      this.$socket.client.emit("setRemoteTarget", { manual: manual, dkey: this.$store.state.dkey });
    });
  },
  sockets: {
    remoteScan: function(data) {
      EventBus.$emit("CardRead", data.ID, data.type);
    },
    removeFromRemoteTarget: function() {
      this.$store.state.remoteTarget = false;
      this.$store.commit("save");
    }
  },
});
