








































import EventBus from "@/classes/EventBus";
import Vue from "vue";

export default Vue.extend({
  props: {
    privilegeLevel: {
      type: Number,
      default: 0
    }
  },
  methods: {
    setRemoteTarget() {
      this.$store.state.remoteTarget = true;
      this.$store.commit("save");
      EventBus.$emit("setRemoteTarget", true);
    },
    reloadClient() {
      location.reload();
    },
    show() {
      this.$bvModal.show("helpModal");
    },
    hide() {
      this.$bvModal.hide("helpModal");
    }
  }
});
