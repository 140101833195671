






































































import Vue from "vue";
import store from "@/store/index";
import axios from "axios";
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";
import Title from "@/components/Title.vue";
import { host } from "@/host";
import EventBus from "@/classes/EventBus";
import CreditCard from "vue-material-design-icons/CreditCard.vue";

export default Vue.extend({
  store,
  components: {
    SimpleKeyboard,
    Title,
    CreditCard
  },
  data: () => ({
    input: "",
    type: "text",
    placeholder: "",
    hint: "",
    loading: false,
    cardLoading: false,
    cardStatus: "",
    stage: "",
    email: "",
    password: ""
  }),
  methods: {
    showSignInWithCard() {
      this.cardStatus = "Please scan or swipe your card to sign in!";
      this.$bvModal.show("SignInWithCard");

      EventBus.$on("CardRead", (ID: string, type: string) => {
        this.cardLoading = true;
        this.cardStatus = "Please wait...";

        axios
          .post(host + "/api/v1/user/login/card", { ID: ID, type: type })
          .then(res => {
            store.state.ckey = res.data.key;
            EventBus.$off("CardRead");

            this.$router.push("/client/user/profile").catch(() => {
              return true;
            });
          })
          .catch(err => {
            if (err.response && err.message) {
              this.cardStatus = err.response.data.status;
            } else {
              this.cardStatus = err.message;
            }
          })
          .then(() => {
            this.cardLoading = false;
          });
      });
    },
    hideSignInWithCard() {
      EventBus.$off("CardRead");
      this.$bvModal.hide("SignInWithCard");
    },
    onChange(input: string) {
      this.input = input;
    },
    onKeyPress(button: string) {
      if (button == "{enter}" && !this.loading) {
        switch (this.stage) {
          case "email":
            this.email = this.input;
            this.setStagePassword();
            break;
          case "password":
            this.setLoading(true);
            this.password = this.input;
            axios
              .post(host + "/api/v1/user/login", {
                email: this.email,
                password: this.password
              })
              .then(res => {
                store.state.ckey = res.data.key;

                this.$router.push("/client/user/profile").catch(() => {
                  return true;
                });
              })
              .catch(err => {
                if (err.response && err.message) {
                  this.hint = err.response.data.status;
                } else {
                  this.hint = err.message;
                }
              })
              .then(() => {
                this.setLoading(false);
              });
            break;
        }
      }
    },
    setStageEmail() {
      this.placeholder = "Email address";
      this.hint = "Enter your email address";
      this.stage = "email";
      this.input = this.email;
      this.type = "text";
    },
    setStagePassword() {
      this.placeholder = "Password";
      this.hint = "Please enter your password";
      this.stage = "password";
      this.input = this.password;
      this.type = "password";
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    onBack() {
      switch (this.stage) {
        case "email":
          this.$router.push("/client").catch(() => {
            return true;
          });
          break;
        case "password":
          this.setStageEmail();
          break;
      }
    },
    onHome() {
      this.$router.push("/client").catch(() => {
        return true;
      });
    }
  },
  mounted() {
    this.setStageEmail();
  }
});
