








































import Vue from "vue";
import axios from "axios";
import Success from "vue-material-design-icons/CheckCircleOutline.vue";
import Failed from "vue-material-design-icons/CloseCircleOutline.vue";
import { host } from "@/host";

export interface OneMark extends Vue {
  isOpen: boolean;
  payWithCard(cardType: string, card: string, dkey: string): void;
  pay(ckey: string, dkey: string, callback: Function): void;
}

export default Vue.extend({
  components: {
    Success,
    Failed
  },
  data: () => ({
    loading: false,
    success: false,
    status: "",
    balance: 0,
    isOpen: false,
    name: "",
    timeoutID: 0
  }),
  methods: {
    pay(ckey: string, dkey: string, callback: Function) {
      if (!this.loading) {
        this.loading = true;
        this.balance = 0;
        this.success = false;
        this.status = "";
        this.name = "";
        this.isOpen = true;
        this.$bvModal.show("oneMarkModal");

        axios
          .post(host + "/api/v1/transaction/buy", {
            key: ckey,
            dkey: dkey,
            item: 1,
            amount: 1
          })
          .then(res => {
            if (callback) {
              callback(res.data.balance);
            }

            this.balance = res.data.balance;
            this.name = res.data.name;
            this.success = true;
          })
          .catch(err => {
            if (err.response && err.message) {
              this.status = err.response.data.status;
            } else {
              this.status = err.message;
            }
            this.success = false;
          })
          .then(() => {
            this.loading = false;

            setTimeout(() => {
              this.$bvModal.hide("oneMarkModal");
              this.isOpen = false;
            }, 3000);
          });
      }
    },
    payWithCard(cardType: string, cardID: string, dkey: string) {
      if (!this.loading) {
        this.loading = true;
        this.balance = 0;
        this.success = false;
        this.status = "";
        this.name = "";

        if (this.isOpen) {
          clearTimeout(this.timeoutID);
        } else {
          this.isOpen = true;
          this.$bvModal.show("oneMarkModal");
        }

        axios
          .post(host + "/api/v1/transaction/buy/card", {
            cardType: cardType,
            card: cardID,
            dkey: dkey,
            item: 1,
            amount: 1
          })
          .then(res => {
            const audio = new Audio(require("../assets/success.mp3"));
            audio.play();

            this.balance = res.data.balance;
            this.name = res.data.name;
            this.success = true;
          })
          .catch(err => {
            const audio = new Audio(require("../assets/thenoise.mp3"));
            audio.play();

            if (err.response && err.message) {
              this.status = err.response.data.status;
            } else {
              this.status = err.message;
            }
            this.success = false;
          })
          .then(() => {
            this.loading = false;

            this.timeoutID = setTimeout(() => {
              this.$bvModal.hide("oneMarkModal");
              this.isOpen = false;
            }, 3000);
          });
      }
    }
  }
});
