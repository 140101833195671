import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import bootstrap from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-material-design-icons/styles.css";

import axios from "axios";
import VueSocketIO from "vue-socket.io-extended";
import { io } from "socket.io-client";
import { host } from "./host";

axios.defaults.timeout = 60000;
axios.defaults.timeoutErrorMessage = "Request timed out";

Vue.use(bootstrap);

const ioInstance = io(host, {
        reconnection: true,
        reconnectionDelay: 500
});

Vue.use(VueSocketIO, ioInstance, {});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");