
























































import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import store from "@/store/index";
import RequestPasswordResetModal from "@/components/RequestPasswordReset.vue";

export default Vue.extend({
  components: {
    RequestPasswordResetModal
  },
  data: () => ({
    status: "Please fill in the form to sign in!",
    email: "",
    password: "",
    error: false,
    loading: false,
    spin: false,
    clickCount: 0
  }),
  methods: {
    onSubmit(e: HTMLFormElement) {
      e.preventDefault();

      if (this.email == "" || this.password == "") {
        this.error = true;
      } else {
        this.error = false;
        this.loading = true;
        this.status = "Please wait...";

        axios
          .post(host + "/api/v1/user/login", {
            email: this.email,
            password: this.password
          })
          .then(res => {
            store.state.ckey = res.data.key;
            store.commit("save");

            this.$router.push("/user").catch(() => {
              return true;
            });
          })
          .catch(err => {
            this.error = true;
            if (err.response && err.message) {
              this.status = err.response.data.status;
            } else {
              this.status = err.message;
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    clickLogo() {
      this.clickCount++;

      if (this.clickCount >= 3 && !this.spin) {
        this.spin = true;
        this.clickCount = 0;

        setTimeout(() => {
          this.spin = false;
        }, 5000);
      }
    }
  }
});
