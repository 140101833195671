class Validator {
  fullname(name: string) {
    if (name) {
      if (name.length == 0) {
        return {
          fail: true,
          status: "Your name must be at least one character!"
        };
      }

      return {
        fail: false,
        status: ""
      };
    } else {
      return {
        fail: true,
        status: "Your name must be at least one character!"
      };
    }
  }

  password(password: string) {
    if (password) {
      if (password.length < 8) {
        return {
          fail: true,
          status: "Your password must be at least 8 characters long!"
        };
      }

      if (password.length > 33) {
        return {
          fail: true,
          status: "Your password must be no more than 32 characters long!"
        };
      }

      return {
        fail: false,
        status: ""
      };
    } else {
      return {
        fail: true,
        status: "You need to input a password!"
      };
    }
  }
}

export default new Validator();
