import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import checks from "@/classes/Checks";
import { AxiosError } from "axios";

import Client from "@/views/client/Client.vue";
import ClientAuthenticate from "../views/client/Authenticate.vue";

import ClientUserLogin from "@/views/client/user/Login.vue";
import ClientUserRegistration from "@/views/client/user/Registration.vue";
import ClientUserProfile from "@/views/client/user/Profile.vue";

import User from "@/views/user/User.vue";
import UserTransactions from "@/views/user/Transactions.vue";
import UserLogin from "@/views/user/Login.vue";
import UserResetPassword from "@/views/user/reset/Password.vue";
import DocumentsPrivacyPolicy from "@/views/documents/PrivacyPolicy.vue";
import DocumentsTermsOfService from "@/views/documents/TermsOfService.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/user"
  },
  {
    path: "/client",
    name: "Client",
    component: Client,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      checks.deviceIsAuthenticated(
        (err: AxiosError, isAuthenticated: boolean) => {
          if (isAuthenticated) next();
          else next({ name: "ClientAuthenticate" });
        }
      );
    }
  },
  {
    path: "/client/authenticate",
    name: "ClientAuthenticate",
    component: ClientAuthenticate,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      checks.deviceIsAuthenticated(
        (err: AxiosError, isAuthenticated: boolean) => {
          if (!isAuthenticated) next();
          else next({ name: "Client" });
        }
      );
    }
  },
  {
    path: "/client/user/login",
    name: "ClientUserLogin",
    component: ClientUserLogin,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      checks.deviceIsAuthenticated(
        (err: AxiosError, isAuthenticated: boolean) => {
          if (isAuthenticated) next();
          else next({ name: "ClientAuthenticate" });
        }
      );
    }
  },
  {
    path: "/client/user/registration",
    name: "ClientUserRegistration",
    component: ClientUserRegistration,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      checks.deviceIsAuthenticated(
        (err: AxiosError, isAuthenticated: boolean) => {
          if (isAuthenticated) next();
          else next({ name: "ClientAuthenticate" });
        }
      );
    }
  },
  {
    path: "/client/user/profile",
    name: "ClientUserProfile",
    component: ClientUserProfile,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      checks.deviceIsAuthenticated(
        (err: AxiosError, isAuthenticated: boolean) => {
          if (isAuthenticated) next();
          else next({ name: "ClientAuthenticate" });
        }
      );
    }
  },
  {
    path: "/user",
    name: "User",
    component: User,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (store.state.ckey != "") next();
      else next({ name: "UserLogin" });
    }
  },
  {
    path: "/user/transactions",
    name: "UserTransactions",
    component: UserTransactions,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      if (store.state.ckey != "") next();
      else next({ name: "UserLogin" });
    }
  },
  {
    path: "/user/login",
    name: "UserLogin",
    component: UserLogin
  },
  {
    path: "/user/reset/password",
    name: "UserResetPassword",
    component: UserResetPassword
  },
  {
    path: "/documents/privacypolicy",
    name: "DocumentsPrivacyPolicy",
    component: DocumentsPrivacyPolicy
  },
  {
    path: "/documents/termsofservice",
    name: "DocumentsTermsOfService",
    component: DocumentsTermsOfService
  }
  //  {
  //    path: "/about",
  //    name: "About",
  //    // route level code-splitting
  //    // this generates a separate chunk (about.[hash].js) for this route
  //    // which is lazy-loaded when the route is visited.
  //    component: () =>
  //      import(/* webpackChunkName: "about" */ "../views/About.vue")
  //  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
