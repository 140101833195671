<template>
  <div>
    <div :class="keyboardClass"></div>
  </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    }
  },
  data: () => ({
    keyboard: null,
    shift: false,
    caps: false
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: [
          "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
          "{tab} q w e r t y u i o p å [ ] \\",
          "{lock} a s d f g h j k l æ ø ; ' {enter}",
          "{shift} z x c v b n m , . / {shift}",
          "@student.sdu.dk @ {space}"
        ],
        shift: [
          "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
          "{tab} Q W E R T Y U I O P Å { } |",
          '{lock} A S D F G H J K L Æ Ø : " {enter}',
          "{shift} Z X C V B N M < > ? {shift}",
          "@student.sdu.dk @ {space}"
        ]
      },
      buttonTheme: [
        {
          class: "sduKey",
          buttons: "@student.sdu.dk"
        }
      ]
    });
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      if (this.shift && button != "{shift}" && button != "{lock}") {
        this.shift = false;

        if (this.caps) {
          this.setShift(true);
        } else {
          this.setShift(false);
        }
      }

      if (button === "{shift}" || button === "{lock}") this.handleShift(button);
    },
    handleShift(button) {
      if (button == "{lock}") {
        if (this.caps) {
          this.caps = false;
          this.setShift(false);
        } else {
          this.caps = true;
          if (this.shift) {
            this.setShift(false);
          } else {
            this.setShift(true);
          }
        }
      } else {
        if (this.shift) {
          this.shift = false;
          if (this.caps) {
            this.setShift(true);
          } else {
            this.setShift(false);
          }
        } else {
          this.shift = true;
          if (this.caps) {
            this.setShift(false);
          } else {
            this.setShift(true);
          }
        }
      }
    },
    setShift(shift) {
      if (shift) {
        this.keyboard.setOptions({
          layoutName: "shift"
        });
      } else {
        this.keyboard.setOptions({
          layoutName: "default"
        });
      }
    }
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    }
  }
};
</script>

<style>
.hg-button {
  height: 60px !important;
  font-size: 20px;
}

.hg-button.hg-activeButton {
  background: #9c9c9c !important;
}

.hg-button.sduKey {
  max-width: 180px;
}

#bottomKeyboardImage {
  border-radius: 5px;
  width: 740px;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}
</style>
