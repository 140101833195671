






























import Vue from "vue";
import HomeIcon from "vue-material-design-icons/Home.vue";
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";

export default Vue.extend({
  name: "Title",
  components: {
    HomeIcon,
    ArrowLeft
  },
  props: {
    title: {
      default: "Title",
      type: String
    },
    subtitle: {
      default: "",
      type: String
    },
    showBack: {
      default: false,
      type: Boolean
    },
    showHome: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    onHome(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        this.$emit("onHome");
      }
    },
    onBack(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        this.$emit("onBack");
      }
    }
  }
});
