import { render, staticRenderFns } from "./OneMark.vue?vue&type=template&id=a57f08a6&scoped=true&"
import script from "./OneMark.vue?vue&type=script&lang=ts&"
export * from "./OneMark.vue?vue&type=script&lang=ts&"
import style0 from "./OneMark.vue?vue&type=style&index=0&id=a57f08a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a57f08a6",
  null
  
)

export default component.exports