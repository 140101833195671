



























import Vue from "vue";
import store from "@/store/index";
import Title from "@/components/Title.vue";
import MultiModeModal from "@/components/MultiMode.vue";
import { MultiMode } from "@/components/MultiMode.vue";
import OneMarkModal from "@/components/OneMark.vue";
import { OneMark } from "@/components/OneMark.vue";
import HelpModal from "@/components/Help.vue";
import EventBus from "@/classes/EventBus";

interface Modal extends Vue {
  show(): void;
}

export default Vue.extend({
  components: {
    Title,
    MultiModeModal,
    OneMarkModal,
    HelpModal
  },
  methods: {
    showhelp(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        (this.$refs.help as Modal).show();
      }
    },
    showmultimode(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        (this.$refs.multimode as Modal).show();
      }
    },
    signup(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        this.$router.push("/client/user/registration").catch(() => {
          return true;
        });
      }
    },
    signin(e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        this.$router.push("/client/user/login").catch(() => {
          return true;
        });
      }
    }
  },
  mounted() {
    EventBus.$on("CardRead", (card: string, cardType: string) => {
      const mm = this.$refs.multimode as MultiMode;
      const om = this.$refs.onemark as OneMark;

      if (!mm.isOpen) {
        om.payWithCard(cardType, card, store.state.dkey);
      } else if (mm.isOpen) {
        mm.payWithCard(cardType, card, store.state.dkey);
      }
    });
  },
  beforeDestroy() {
    EventBus.$off("CardRead");
  }
});
