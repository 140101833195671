















































































import Vue from "vue";
import axios from "axios";
import Plus from "vue-material-design-icons/Plus.vue";
import Minus from "vue-material-design-icons/Minus.vue";
import Success from "vue-material-design-icons/CheckCircleOutline.vue";
import { host } from "@/host";

export interface MultiMode extends Vue {
  isOpen: boolean;
  payWithCard(cardType: string, card: string, dkey: string): void;
  show(ckeyVar: boolean, ckey: string, dkey: string, callback: Function): void;
}

export default Vue.extend({
  components: {
    Plus,
    Minus,
    Success
  },
  data: () => ({
    amount: 1,
    minAmount: true,
    loading: false,
    success: false,
    status: "",
    balance: 0,
    isOpen: false,
    isDone: true,
    dkey: "",
    ckeyVar: false,
    ckey: "",
    name: "",
    callback(balance: number) {
      return balance;
    }
  }),
  methods: {
    pay() {
      if (!this.loading && this.isDone) {
        this.isDone = false;
        this.loading = true;
        this.name = "";
        this.status = "Please wait...";

        axios
          .post(host + "/api/v1/transaction/buy", {
            key: this.ckey,
            dkey: this.dkey,
            item: 1,
            amount: this.amount
          })
          .then(res => {
            this.callback(res.data.balance);
            this.balance = res.data.balance;
            this.name = res.data.name;
            this.success = true;

            setTimeout(() => {
              this.hide();
            }, 3000);
          })
          .catch(err => {
            this.isDone = true;
            if (err.response && err.message) {
              this.status = err.response.data.status;
            } else {
              this.status = err.message;
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    payWithCard(cardType: string, cardID: string, dkey: string) {
      if (!this.loading && this.isDone) {
        this.isDone = false;
        this.loading = true;
        this.name = "";
        this.status = "Please wait...";

        axios
          .post(host + "/api/v1/transaction/buy/card", {
            cardType: cardType,
            card: cardID,
            dkey: dkey,
            item: 1,
            amount: this.amount
          })
          .then(res => {
            const audio = new Audio(require("../assets/success.mp3"));
            audio.play();

            this.balance = res.data.balance;
            this.name = res.data.name;
            this.success = true;

            setTimeout(() => {
              this.hide();
            }, 3000);
          })
          .catch(err => {
            const audio = new Audio(require("../assets/thenoise.mp3"));
            audio.play();

            this.isDone = true;
            if (err.response && err.message) {
              this.status = err.response.data.status;
            } else {
              this.status = err.message;
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    add(amount: number, e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        if (document.activeElement) {
          (document.activeElement as HTMLButtonElement).blur();
        }

        this.amount += amount;

        if (this.minAmount) {
          this.minAmount = false;
        }
      }
    },
    subtract(amount: number, e: MouseEvent) {
      if (e.x != 0 && e.y != 0) {
        if (document.activeElement) {
          (document.activeElement as HTMLButtonElement).blur();
        }

        if (this.amount > amount) {
          this.amount -= amount;

          if (this.amount == 1) {
            this.minAmount = true;
          }
        }
      }
    },
    hide() {
      this.isDone = true;
      this.isOpen = false;
      this.$bvModal.hide("multimodeModal");
    },
    show(ckeyVar: false, ckey: string, dkey: string, callback: Function) {
      if (ckeyVar) {
        this.ckeyVar = true;
        this.ckey = ckey;
        this.dkey = dkey;
        this.status = "Press confirm to complete the transaction!";
      } else {
        this.ckeyVar = false;
        this.ckey = "";
        this.dkey = "";
        this.status = "Scan your card to complete the transaction!";
      }

      if (callback) {
        this.callback = (balance: number) => {
          return callback(balance);
        };
      } else {
        this.callback = (balance: number) => {
          return balance;
        };
      }

      this.success = false;
      this.minAmount = true;
      this.amount = 1;
      this.$bvModal.show("multimodeModal");
      this.isOpen = true;
    }
  }
});
