
















































import Vue from "vue";
import Success from "vue-material-design-icons/CheckCircleOutline.vue";

export interface Confirm extends Vue {
  onFail(error: string): void;
  onSuccess(): void;
  show(): void;
  hide(): void;
}

export default Vue.extend({
  components: {
    Success
  },
  data: () => ({
    message: "",
    successMessage: "",
    status: "",
    loading: false,
    success: false,
    onConfirm() {
      this.success = true;
      return true;
    }
  }),
  methods: {
    onSuccess() {
      this.success = true;

      setTimeout(() => {
        this.hide();
      }, 3000);
    },
    onFail(error: string) {
      this.status = error;
      this.loading = false;
    },
    show(message: string, successMessage: string, onConfirm: Function) {
      this.success = false;
      this.loading = false;
      this.status = "";
      this.message = message;
      this.successMessage = successMessage;

      this.onConfirm = () => {
        this.loading = true;
        this.status = "Please wait...";
        onConfirm();
        return true;
      };

      this.$bvModal.show("confirmModal");
    },
    hide() {
      this.$bvModal.hide("confirmModal");
    }
  }
});
