

























import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import store from "@/store/index";

export default Vue.extend({
  data: () => ({
    publicPath: process.env.BASE_URL
  }),
  methods: {
    logout() {
      axios.delete(host + "/api/v1/user/login", {
        params: { key: store.state.ckey }
      });
      store.state.ckey = "";
      store.commit("save");

      this.$router.push({ path: "/user/login" }).catch(() => {
        return true;
      });
    }
  }
});
