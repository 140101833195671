import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import { cookieAddress, cookieSecure } from "@/host";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ckey: "",
    dkey: "",
    remoteTarget: false,
    newAccount: false
  },
  plugins: [
    createPersistedState({
      key: "session",
      paths: ["ckey"],
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, {
            secure: cookieSecure,
            path: "/",
            domain: cookieAddress,
            sameSite: "strict"
          }),
        removeItem: key => Cookies.remove(key)
      }
    }),
    createPersistedState({
      key: "perm",
      paths: ["dkey"],
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, {
            expires: 9999,
            secure: cookieSecure,
            path: "/",
            domain: cookieAddress,
            sameSite: "strict"
          }),
        removeItem: key => Cookies.remove(key)
      }
    }),
    createPersistedState({
      key: "remoteTarget",
      paths: ["remoteTarget"],
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, {
            expires: 9999,
            secure: cookieSecure,
            path: "/",
            domain: cookieAddress,
            sameSite: "strict"
          }),
        removeItem: key => Cookies.remove(key)
      }
    })
  ],
  mutations: {
    save: () => {
      true;
    }
  },
  actions: {},
  modules: {}
});
