


















































import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import store from "@/store/index";
import Success from "vue-material-design-icons/CheckCircleOutline.vue";

export default Vue.extend({
  components: {
    Success
  },
  data: () => ({
    status: "",
    check: "",
    loading: false,
    success: false
  }),
  methods: {
    deleteAccount() {
      if (this.check !== "I am sure") {
        this.status = "Please read the text!";
        return false;
      }

      this.loading = true;
      this.status = "Please wait...";

      axios
        .delete(host + "/api/v1/user", { params: { key: store.state.ckey } })
        .then(() => {
          this.success = true;
          store.state.ckey = "";
          store.commit("save");

          setTimeout(() => {
            this.$router.push("/user/login").catch(() => {
              return true;
            });
          }, 5000);
        })
        .catch(err => {
          this.loading = false;
          if (err.response && err.message) {
            this.status = err.response.data.status;
          } else {
            this.status = err.message;
          }
        });
    },
    show() {
      this.status = "";
      this.check = "";
      this.$bvModal.show("delAccModal");
    },
    hide() {
      this.$bvModal.hide("delAccModal");
    }
  }
});
