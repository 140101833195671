

























































import Vue from "vue";
import axios from "axios";
import { host } from "@/host";
import store from "@/store/index";
import Success from "vue-material-design-icons/CheckCircleOutline.vue";

export default Vue.extend({
  components: {
    Success
  },
  data: () => ({
    password: "",
    repeatPassword: "",
    status: "",
    error: false,
    loading: false,
    success: false
  }),
  methods: {
    makeRequest() {
      if (this.password == "") {
        this.error = true;
        this.status = "Please enter a password!";
        return false;
      }

      if (this.password != this.repeatPassword) {
        this.error = true;
        this.status = "The passwords do not match!";
        return false;
      }

      this.loading = true;
      this.error = false;
      this.status = "Please wait...";

      axios
        .post(host + "/api/v1/user/update/password", {
          key: store.state.ckey,
          password: this.password
        })
        .then(() => {
          this.success = true;
          this.loading = false;

          setTimeout(() => {
            this.hide();
          }, 3000);
        })
        .catch(err => {
          this.error = true;
          this.loading = false;
          if (err.response && err.message) {
            this.status = err.response.data.status;
          } else {
            this.status = err.message;
          }
        });
    },
    show() {
      this.status = "";
      this.password = "";
      this.repeatPassword = "";
      this.success = false;
      this.error = false;

      this.$bvModal.show("passupdateModal");
    },
    hide() {
      this.$bvModal.hide("passupdateModal");

      setTimeout(() => {
        this.status = "";
        this.password = "";
        this.repeatPassword = "";
      }, 1000);
    }
  }
});
