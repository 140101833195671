














































import Vue from "vue";
import store from "@/store/index";
import axios from "axios";
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";
import Title from "@/components/Title.vue";
import { host } from "@/host";
import Validator from "@/classes/Validation";

export default Vue.extend({
  store,
  components: {
    SimpleKeyboard,
    Title
  },
  data: () => ({
    input: "",
    type: "text",
    placeholder: "",
    hint: "",
    loading: false,
    stage: "",
    email: "",
    name: "",
    password: "",
    repeatPassword: ""
  }),
  methods: {
    onChange(input: string) {
      this.input = input;
    },
    onKeyPress(button: string) {
      if (button == "{enter}" && !this.loading) {
        switch (this.stage) {
          case "email":
            this.setLoading(true);
            this.hint = "Please wait, this might take a while...";

            axios
              .get(host + "/api/v1/validator/email", {
                params: { email: this.input }
              })
              .then(res => {
                this.email = this.input;

                this.setStageName();
              })
              .catch(err => {
                if (err.response && err.message) {
                  this.hint = err.response.data.status;
                } else {
                  this.hint = err.message;
                }
              })
              .then(() => {
                this.setLoading(false);
              });
            break;
          case "name": {
            const res = Validator.fullname(this.input);
            if (res.fail) {
              this.hint = res.status;
            } else {
              this.name = this.input;
              this.setStagePassword();
            }
            break;
          }
          case "password": {
            const res = Validator.password(this.input);
            if (res.fail) {
              this.hint = res.status;
            } else {
              this.password = this.input;
              this.setStageRepeatPassword();
            }
            break;
          }
          case "repeatPassword":
            if (this.password == this.input) {
              this.setLoading(true);
              axios
                .post(host + "/api/v1/user", {
                  email: this.email,
                  name: this.name,
                  password: this.password,
                  key: store.state.dkey
                })
                .then(res => {
                  store.state.newAccount = true;
                  store.state.ckey = res.data.key;

                  this.$router.push("/client/user/profile").catch(() => {
                    return true;
                  });
                })
                .catch(err => {
                  this.hint = err.response.data.status;
                })
                .then(() => {
                  this.setLoading(false);
                });
            } else {
              this.hint = "The two passwords do not match!";
            }
            this.repeatPassword = this.input;
            break;
        }
      }
    },
    setStageEmail() {
      this.placeholder = "Email address";
      this.hint = "Enter your SDU email address";
      this.stage = "email";
      this.input = this.email;
      this.type = "text";
    },
    setStageName() {
      this.placeholder = "Name";
      this.hint = "Please enter your name";
      this.stage = "name";
      this.input = this.name;
      this.type = "text";
    },
    setStagePassword() {
      this.placeholder = "Password";
      this.hint = "Please enter a password";
      this.stage = "password";
      this.input = this.password;
      this.type = "password";
    },
    setStageRepeatPassword() {
      this.placeholder = "Password";
      this.hint = "Please enter the password again";
      this.stage = "repeatPassword";
      this.input = this.repeatPassword;
      this.type = "password";
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    onBack() {
      switch (this.stage) {
        case "email":
          this.$router.push("/client").catch(() => {
            return true;
          });
          break;
        case "name":
          this.setStageEmail();
          break;
        case "password":
          this.setStageName();
          break;
        case "repeatPassword":
          this.setStagePassword();
          break;
      }
    },
    onHome() {
      this.$router.push("/client").catch(() => {
        return true;
      });
    }
  },
  mounted() {
    this.setStageEmail();
  }
});
